<template>
  <div class="tool-salary">
    <div class="title">Cập nhật lương theo Salon/Bộ phận/Nhân viên »</div>
    <div class="content">
      <a-date-picker
        style="width: 200px"
        @change="(e, dateString) => handleChange('fromDateString', dateString)"
        placeholder="Từ ngày"
        :format="dateFormat"
        :disabledDate="selectDateDisabled"
      />
      <a-date-picker
        style="width: 200px"
        @change="(e, dateString) => handleChange('toDateString', dateString)"
        placeholder="Đến ngày"
        :format="dateFormat"
        :disabledDate="selectDateDisabled"
        :style="{ marginLeft: '25px' }"
      />
      <br />
      <br />
      <div class="title-sub">Cập nhật theo nhân viên</div>
      <hr />
      <br />
      <a-row :style="{ marginBottom: '20px' }">
        <a-col :span="5" :offset="4"> <label>Cấu phần lương: </label></a-col>
        <a-col :span="15">
          <a-select
            allowClear
            show-search
            :filter-option="filterOption"
            placeholder="Chọn cấu phần lương"
            style="width: 300px"
            @change="e => handleChange('typeUpdateSalary', e)"
          >
            <a-select-option v-for="item in listSalary" :value="item.id" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :style="{ marginBottom: '20px' }">
        <a-col :span="5" :offset="4"> <label>Nhân viên: </label></a-col>
        <a-col :span="15">
          <a-input
            placeholder="danh sách id nhân viên (1;2;3;...)"
            type="text"
            style="width: 300px"
            @change="e => handleChange('staffId', e.target.value)"
          >
          </a-input>
        </a-col>
      </a-row>
      <a-row :style="{ marginBottom: '20px' }">
        <a-col :span="5" :offset="4"></a-col>
        <a-col :span="15">
          <a-button
            :loading="loading.loading3"
            type="primary"
            @click="() => onClick()"
            class="btn-green"
            :style="{ width: '300px' }"
            >Cập nhật theo nhân viên
          </a-button>
          <div class="my-process" v-if="loading.loading3">
            <a-progress :percent="percent" status="active" />
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getFactory } from '@/api'
import { mapGetters } from 'vuex'
import moment from 'moment'
const api = getFactory('fluctuation')
const getListStaff = getFactory('getListStaff')

export default {
  components: {},
  data() {
    return {
      dateFormat: 'DD/MM/YYYY',
      listSalary: [
        {
          id: 0,
          value: 0,
          name: "Chạy all cấu phần"
        },{
          id: 1,
          value: 1,
          name: "Lương cứng"
        },{
          id: 2,
          value: 2,
          name: "Thưởng dịch vụ"
        },{
          id: 3,
          value: 3,
          name: "Thưởng upsales"
        },{
          id: 4,
          value: 4,
          name: "Thưởng bán sản phẩm"
        }
      ],
      percent: 0,
      params: {
        fromDateString: '',
        toDateString: '',
        staffId: 0,
        typeUpdateSalary: 0
      },
      loading: {
        loading3: false
      },
      selectDateDisabled: null
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(key, value) {
      this.params = { ...this.params, [key]: value }
    },
    onClick() {
      const { staffId } = this.params
      if (!staffId) {
        this.$message.warn('Mời bạn nhập danh sách id nhân viên!')
      } else {
        this.resetSarary('loading3', { staffId: staffId })
      }
    },
    async resetSarary(key, data) {
      const { fromDateString, toDateString, typeUpdateSalary } = this.params
      if (!fromDateString || !toDateString) {
        this.$message.warn('Bạn chưa chọn ngày chạy lại!')
        return
      }

      try {
        this.percent = 0;
        this.loading = { ...this.loading, [key]: true }
        let listStaff = []
        if (!data.staffId) {
          listStaff = await getListStaff.get(data)
        } else {
          if(data.staffId != null && data.staffId != ""){
            var listStaffTempt = data.staffId.split(';')
            for(var j = 0; j < listStaffTempt.length; j++){
              var itemStaff = {id : Number(listStaffTempt[j]) }
              listStaff.push(itemStaff)
            }
          }
        }
        let listRerunSendMail = []
        for (let i = 0; i < listStaff.length; i++) {
          let responseStaff = await api.runToolSalaryV3({
            staffId: listStaff[i].id,
            fromDateString: fromDateString,
            toDateString: toDateString,
            typeUpdateSalary: typeUpdateSalary != null ? typeUpdateSalary : 0
          })
          this.percent = (i + 1) * Math.round(100 / listStaff.length)
          if(responseStaff != null && responseStaff.length > 0){
            listRerunSendMail = [...listRerunSendMail, ...responseStaff]
          }
        }
        if(listRerunSendMail != null && listRerunSendMail.length > 0){
          let staffName = this.user.fullName
          let typeSalary = ''
          let getType = this.listSalary.filter(x=>x.id == (typeUpdateSalary != null ? typeUpdateSalary : 0))
          if(getType != null && getType.length > 0){
            typeSalary = getType[0].name
          }
          await api.sendMailRerunSalary(staffName, typeSalary, fromDateString, toDateString, listRerunSendMail)
        }
        this.$message.success('Chạy lại lương thành công!')
      } catch (e) {
        console.log(e)
      }
      this.loading = { ...this.loading, [key]: false }
    }
  },
  watch: {
    params() {
      console.log('parmas', this.params)
    }
  },
  created() {
    this.selectDateDisabled = current => {
      return current && (current < moment().subtract(11, 'days') || current > moment())
    }
  }
}
</script>

<style lang="less" scoped>
.tool-salary {
  padding: 20px;
  background: #fff;
  label {
    line-height: 32px;
    font-size: 16px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 25px;
  }
  .title-sub {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }
  .content {
    width: 100%;
    max-width: 700px;
  }
  .btn-green {
    background: #1da57a;
    border: 1px solid #1da57a;
    &:hover {
      background: #46bc97;
    }
  }
  .my-process {
    width: 310px;
  }
}
</style>
